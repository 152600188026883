<template>
    <van-tabbar
        v-if="show"
        v-model="active"
        active-color="#775fd9"
        :border="true"
        inactive-color="#c54593"
    >
        <van-tabbar-item
            v-for="(k, key) in item"
            replace
            :to="k.router"
            :key="key"
        >
            <span>{{ k.title }}</span>
            <template #icon="props">
                <img
                    :src="props.active ? k.icon.active : k.icon.noactive"
                    :alt="k.title"
                    v-show="key !== 2"
                />
                <img
                    :src="props.active ? k.icon.active : k.icon.noactive"
                    :alt="k.title"
                    class="tui"
                    style="height: 4rem"
                    v-show="key === 2"
                />
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            active: 0,
            item: [
                {
                    router: '/Home',
                    //   title: '首页',
                    title: this.$t('common.tab_bar.tab_home'),
                    icon: {
                        active: '/img/footer/首页选中.jpg',
                        noactive: '/img/footer/首页未选中.jpg'
                    }
                },
                // {
                //     router: '/Game',
                //     //   title: "预约",
                //     title: this.$t('common.tab_bar.tab_reserve'),
                //     icon: {
                //         active: '/img/footer/预约选中.jpg',
                //         noactive: '/img/footer/预约未选中.jpg'
                //     }
                // },
                {
                    router: '/Contract/index',
                    //   title: "预约",
                    title: this.$t('认证'),
                    icon: {
                        active: '/img/footer/预约选中.jpg',
                        noactive: '/img/footer/预约未选中.jpg'
                    }
                },
                {
                    router: '/Choose',
                    title: '',//this.$t('common.tab_bar.tab_xy')
                    icon: {
                        active: '/img/footer/beauty.52660ad1.png',
                        noactive: '/img/footer/beauty.52660ad1.png'
                    }
                },
                {
                    router: '/Video',
                    //   title: "视频",
                    title: this.$t('common.tab_bar.tab_video'),
                    icon: {
                        active: '/img/footer/视频选中.jpg',
                        noactive: '/img/footer/视频未选中.jpg'
                    }
                },
                {
                    router: '/Mine',
                    //   title: "我的",
                    title: this.$t('common.tab_bar.tab_mine'),
                    icon: {
                        active: '/img/footer/我的选中.jpg',
                        noactive: '/img/footer/我的未选中.jpg'
                    }
                }
            ]
        }
    },
    methods: {},
    watch: {
        $route(to) {
            if (to.name == 'home') {
                this.active = 0
                this.show = true
            } else if (to.name == 'trade') {
                this.active = 1
                this.show = true
            } else if (to.name == 'choose') {
                this.active = 2
                this.show = true
            } else if (to.name == 'video') {
                this.active = 3
                this.show = true
            } else if (to.name == 'mine') {
                this.active = 4
                this.show = true
            } else {
                this.show = false
            }
        }
    },
    created() {
        if (this.$route.name == 'home') {
            this.active = 0
            this.show = true
        } else if (this.$route.name == 'trade') {
            this.active = 1
            this.show = true
        } else if (this.$route.name == 'choose') {
            this.active = 2
            this.show = true
        } else if (this.$route.name == 'video') {
            this.active = 3
            this.show = true
        } else if (this.$route.name == 'mine') {
            this.active = 4
            this.show = true
        } else {
            this.show = false
        }
    }
}
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 75px;
.van-tabbar {
    height: @tabbar-height;
    z-index: 5;
}
.van-tabbar-item__icon img {
    height: @tabbar-img;
}
.van-tabbar-item {
    font-size: 2.933vw;
}
.tui {
    width: 4.3rem;
    height: 4.3rem !important;
    margin-top: -4vw;
    background-color: #fff;
    border-radius: 50%;
    border: 1.333vw solid #fff;
    z-index: 10;
}
.van-tabbar-item__text span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 500;
    text-align: center;
}
[class*='van-hairline']::after {
    border: none !important;
}
</style>
