export default {
    // 通用
    common: {
      app_name: "",
      tab_bar: {
        tab_home: "Trang chủ",
        tab_reserve: "Đặt vị trí",
        tab_concubine: "Lựa chọn",
        tab_video: "Rạp chiếu phim",
        tab_mine: "Của tôi",
      },
      view_more: "Xem thêm",
      play: "chơi",
      finished_text: "không có nhiều hơn",
      description: "Dữ liệu trống！",
      Details: "Chi tiết",
      disabled: "Tính năng bị vô hiệu hóa",
      offline: "Tài khoản ngoại tuyến",
        Sure: "xác nhận",
        Cancel:"Hủy bỏ",
      notset: "Chưa cài đặt",
      Hasbeenset: "Đã cài đặt",
      logout: "Trở về đăng nhập",
      currency: "Đồng",
      submit: "Gửi lệnh",
        save: "lưu",
        success:"Hoàn thành",
    },
    login: {
      login: "Đăng nhập",
      forget_pwd: "Quên mật khẩu？",
      to_reg: "Chưa có tài khoản, đăng ký",
      inp_username: "Vui lòng nhập tài khoản",
      inp_pwd: "Vui lòng nhập mật khẩu",
    },
    register: {
      title: "Đăng ký",
      inp_user: "Vui lòng nhập tài khoản(6-12 ký tự hoặc số)",
      inp_pass: "Vui lòng nhập mật khẩu(6-12 ký tự hoặc số)",
      inp_code: "Vui lòng nhập mã giới thiệu",
      agreement: 'Tôi đã hiểu và đồng ý với các điều khoản của “Thỏa thuận mở tài khoản”​',
      reg_btn: "Đăng ký",
      click_agreement: "Vui lòng kiểm tra thỏa thuận mở tài khoản bên dưới！",
    },
    game: {
      title: "Phòng đặt chỗ",
      all: "Tất cả",
      xzmoney: "Đã đặt",
      create_time: "Thời gian đặt",
      update_time: "Thời gian tổng kết",
      Gamerules: "Cách chơi",
      gametip: "Lời khuyên về cách chơi",
      gamedetial: "Chọn số từ định dạng tổng tùy chọn để đặt cược",
      Winninginstructions: "Hướng dẫn chiến thắng",
      Winningtip: "Tổng của ba số xổ số là 11 đến 18 là lớn, tổng từ 3 đến 10 là nhỏ.;",
      Bettingexample: "Ví dụ cá cược",
      Bettingexampletip: "Kế hoạch đặt cược: nhỏ; số xổ số: 123, nghĩa là vừa và nhỏ",
      missionlist: "danh sách nhiệm vụ",
      AvailableBalance: "Số dư khả dụng",
      numberselection: "Lựa chọn hiện tại",
      Amountperbet: "Số tiền mỗi lần đặt cược",
      Pleaseentertheamount: "Vui lòng nhập số tiền",
      total: "Tổng cộng",
      total1: "tổng cộng",
      note: "Ghi chú",
      Clearorder: "Xóa đơn hàng",
      submission: "xác nhận gửi",
      Issue: "Số kỳ",
      Winningnumbers: "Mã số trúng thưởng",
      Notselected: "Không lựa chọn",
      Wrongamount: "Sai số tiền！",
      selectanumber: "Vui lòng chọn một số",
      Pleasefillintheamount: "Vui lòng điền số tiền！",
      servicetorecharge: "Nếu số dư không đủ, vui lòng liên hệ bộ phận chăm sóc khách hàng để nạp tiền​！",
      Unselected: "Không được chọn",
      thistask: "Vui lòng liên hệ với quản trị viên để nhận nhiệm vụ này!",
        Thelotterydraw: "mở giải thành công, số phát hành：",
        Tasktype: "Loại nhiệm vụ",
        timeleft:"Thời gian còn lại để mua",
    },
    //   首页
    home: {
      rec_tasks: "Nhiệm vụ được đề xuất",
      pupu_rank: "Xếp hạng phổ biến",
      rec_hot: "Khuyến nghị phổ biến",
      getting: "Đang nhận......",
      refresh_successful: "Làm mới thành công",
    },
    //   我的
    mine: {
      check_in: "Đăng nhập hàng ngày",
      check_in_box_title: "Bạn đã đăng nhập vào tháng này{dayNum}Ngày",
      check_in_toast: "Hôm nay đã đăng nhập",
      check_in_tips_title: "Quy tắc khen thưởng:",
      check_in_tips_1: "Tất cả các thành viên Câu lạc bộ Nền tảng đều được hưởng các khoản trợ cấp ngày đặc biệt do [LAUXANH] tài trợ đặc biệt. Nếu bạn đăng nhập trong 3 ngày liên tiếp, câu lạc bộ sẽ tặng bạn phần thưởng 30.000 yên và phiếu giảm giá khách sạn miễn phí.",
      check_in_tips_2: "Chỉ dành cho thành viên câu lạc bộ",
      check_in_tips_3: "3：Nếu bạn làm việc 7 ngày liên tục, bạn sẽ được hẹn hò miễn phí với 1 cô gái (tùy chọn)。",
      cardtitle: "Điền vào thẻ thanh toán",
      inp_cardtitle: "Vui lòng nhập thông tin thẻ thanh toán của bạn",
      lab_bankid: "Số tk ngân hàng",
      inp_bank: "tên ngân hàng",
      bindCardnote:
        "Kính gửi người dùng, để đảm bảo an toàn cho tiền của bạn, vui lòng liên kết tên thật của bạn và đặt mật khẩu rút tiền, nếu tên của bạn không trùng với tên mở tài khoản, bạn sẽ không thể rút tiền.",
      bindCard: "xác nhận liên kết thẻ",
      inp_bankid: "Vui lòng nhập số tk ngân hàng",
      select_bank: "vui lòng lựa chọn ngân hàng",
      gamerecord: "Lịch sử nhiệm vụ",
      Recharge: "Nạp tiền",
      withdraw: "Rút tiền",
      Mypurse: "Ví của tôi",
      balance: "Số dư (Đồng)",
      personalreport: "Biểu cá nhân",
      Accountdetails: "Chi tiết tài khoản",
      PersonalCenter: "Trung tâm cá nhân",
      announcement: "Thông tin quảng cáo",
      onlineservice: "CSKH trực tuyến",
      enter: "Vui lòng hoàn thành danh sách nhiệm vụ trước khi vào",
      servicerecharge: "Vui lòng liên hệ CSKH để nạp tiền",
      loginRegister: "Đăng nhập/Đăng ký",
      logintip: "Đăng nhập để tận hưởng nhiều dịch vụ hơn！",
      Information: "Thông tin cơ bản",
      herderImg: "hình đại diện",
      selectimg: "lựa chọn hình dại diện",
      actualname: "tên thật",
      sex: "giới tính",
      male: "nam",
      female: "nữ",
      unknown: "không xác định",
      bindinginformation: "thông tin ràng buộc",
      bound: "Đã liên kết",
      none: "Không",
      cardinformation: "Thông tin thẻ nạp",
      addpaymentcard: "Thêm thẻ thanh toán",
      cardtips: "Mẹo: Vui lòng liên kết với một ngân hàng thương mại lớn, nếu bạn cần sửa đổi, vui lòng liên hệ với bộ phận dịch vụ khách hàng trực tuyến.",
      Setname: "Vui lòng đặt tên của bạn trước khi ràng buộc thẻ ngân hàng！",
      SetPayPassword: "Vui lòng đặt mật khẩu rút tiền trước khi ràng buộc thẻ ngân hàng！",
      gendermodification: "Sửa đổi giới tính",
      setup: "Cài đặt",
      Basicinformation: "Cài đặt thông tin cơ bản",
      loginpassword: "Mật khẩu đăng nhập",
      fundpassword: "Mật khẩu rút tiền",
      withdrawalpassword: "Mật khẩu rút tiền đã được đặt, vui lòng liên hệ với bộ phận dịch vụ khách hàng nếu bạn cần sửa đổi nó.",
      Withdrawalcenter: "Trung tâm rút tiền",
      Withdrawalsrecord: "Lịch sử rút tiền",
      withdrawMoney: "Số tiền rút (Đồng)",
      withdrshowPopovermin: "1.Hạn mức giao dịch duy nhất: tối thiểu",
      withdrshowPopovermax: "Cao nhất",
      widtdrnum: "2.Số lần rút: Rút tối đa trong 1 ngày",
      withdrsucces: "3.Thời gian đến: Thời gian đến chung là khoảng 5 phút và thời gian đến nhanh nhất là 2 phút.",
      Limitdescription: "Giới hạn mô tả",
      Withdrawimmediately: "Lập tức rút tiền",
      correctamount: "Vui lòng điền đúng số tiền",
      Setfundpassword: "Cài đặt mật khẩu rút tiền",
      enterthefundpassword: "Vui lòng nhập mật khẩu rút tiền",
      enterthefundpasswordagain: "Nhập lại mật khẩu rút tiền",
      Pleasefillinthecomplete: "Vui lòng điền đầy đủ",
      twicepassword: "Hai mật khẩu không nhất quán!",
      Changerealname: "Sửa đổi tên thật",
      Name: "Tên",
      pleaseenteryourrealname: "Vui lòng nhập tên thật",
      changeralnametip: "Để đảm bảo tính bảo mật cho tài khoản của bạn, tên thật của bạn phải trùng với tên trên thẻ ngân hàng gắn liền với nó.",
      Donotrepeatsettings: "Không lặp lại cài đặt！",
      Changeloginpassword: "Sửa đổi mật khẩu đăng nhập",
      OldPassword: "Mật khẩu cũ",
      newpassword: "Mật khẩu mới",
      inp_OldPassword: "Vui lòng nhập mật khẩu cũ  của bạn",
      inp_newpassword: "Vui lòng nhập mật khẩu mới  của bạn",
      inp_newpassword1: "Vui lòng nhập lại mật khẩu mới  của bạn",
      connect: "Liên hệ",
      servicelistItemInfoText: "Phục vụ tận tình cho bạn 7*24 giờ một ngày",
      personalreporttitle: "Số tiền lãi(Đồng)",
      personalreporttip: "Công thức tính lợi nhuận: số tiền thắng - số tiền nhiệm vụ",
      personalreportplay_money: "Số tiền nhiệm vụ",
      personalreportrecharge: "Số tiền nạp",
      personalreportwithdrawal: "Số tiền rút",
        personalreportwin_money: "Số tiền  trúng",
        inp_name: "Vui lòng nhập tên thật",
        rechargecenter: "Loại hình nạp tiền",
        rechargerecord: " Lịch sử nạp tiền",
        banktype: "Thẻ ngân hàng",
        Depositamount: "số tiền gửi",
      depositamount: "số tiền ký gửitối đa",
      minimumdepositamount: "Số tiền gửi tối thiểu",
      pleasecontact: "Vui lòng liên hệ",
      next: "Kế tiếp",
      bankinfo: "thông tin ngân hàng",
      transferinformation: "Chuyển thông tin",
      copy: "Sao chép",
      copysuccessfully: "sao chép thành công",
      copyerr: "sao chép thất bại，Vui lòng sao chép thủ công",
      uprechargeimg: "Tải lên hình ảnh chuyển tiền",
      selectfileimg: "Vui lòng chọn ảnh trước",
      pleaseinpmoney: "Vui lòng nhập đúng số tiền gửi",
      submiterr: "Gửi không thành công",
      rechargetsuccess: "Đơn đặt cọc đã hoàn tất，Đơn đăng ký gửi tiền của bạn đã được gửi thành công",
      Amount: "Số lượng",
      illustrate: "minh họa",
      Submissiontime: "Thời gian nộp hồ sơ",
        Reviewtime: "thời gian xét duyệt",
        setlang:"cài đặt ngôn ngữ",
    },
    //   预约
    reserve: {},
    //   选妃
    concubine: {
      choosetitle: "Lựa chọn",
      city: "Danh mục thành phố",
      cityinfo:
        "Nền tảng này là thiết bị ngoại vi + hộ tống doanh nghiệp + niềm đam mê địa phương đích thực nhất trong toàn bộ mạng. Để đảm bảo quyền riêng tư cá nhân của mỗi người dùng, khách hàng chỉ có thể tham gia thông qua đề xuất tên thật bằng cách liên hệ với nhân viên lễ tân hoặc thành viên cấp cao của nền tảng.。",
      processprice: "giá quá trình",
      rigtitle1: "Có những nguồn lực nào??",
      rigtitle2: "Khu vực phục vụ?",
      rigcontent1:
        "Những người nổi tiếng trên Internet, người mẫu, tiếp viên hàng không, người mẫu trẻ, sinh viên đại học, bạn không thể nghĩ tới, nhưng sẽ không thể thực hiện được nếu không có nền tảng này",
      rigcontent2:
        "Quan hệ gần gũi hoàn toàn miễn phí trong cùng một thành phố, có sẵn tại bất kỳ địa điểm nào trên toàn quốc, có sẵn tại địa phương ở các thành phố hạng nhất và hạng hai ở Trung Quốc và ở các thành phố hạng ba, bạn cũng có thể đặt lịch hẹn bằng cách liên hệ với nhân viên lễ tân。",
      rigcontent3:
        "Nền tảng này là thiết bị ngoại vi + hộ tống doanh nghiệp + niềm đam mê địa phương đích thực nhất trong toàn bộ mạng. Để đảm bảo quyền riêng tư cá nhân của mỗi người dùng, khách hàng chỉ có thể tham gia thông qua đề xuất tên thật bằng cách liên hệ với nhân viên lễ tân hoặc thành viên cấp cao của nền tảng.。",
      Contactanadvisor:"Liên hệ với cố vấn",
    },
    //   video
    video: {
      title: "rạp chiếu phim",
      videoInfocount: "vở kịch",
      Popularrecommendations: "Khuyến nghị phổ biến",
      rechargevideo: "Vui lòng nạp tiền để xem video！",
    },
    "认证": "Chứng nhận",
    "视频认证": "Xác thực video",
    "评分": "điểm",
    "身高": "chiều cao",
    "地域": "khu vực",
    "降落伞": "dù",
    "现在就答应她": "Hãy hứa với cô ấy ngay bây giờ",
    "是的": "Đúng",
    "不是": "KHÔNG",
    "胸围": "chu vi ngực",
    "请联系管理员": "Vui lòng liên hệ với quản trị viên",
    "提交成功": "Gửi thành công",
    "顶部": "đứng đầu",
    "支店名称": "Tên chi nhánh",
    "请输入支店名称": "Vui lòng nhập tên chi nhánh",
    "支店号码": "Số chi nhánh",
    "请输入支店号码": "Vui lòng nhập số chi nhánh",
    "交易类型": "loại giao dịch",
    "请输入交易类型": "Vui lòng nhập loại giao dịch",
    "账户号码": "Số tài khoản",
    "请输入账户号码": "Vui lòng nhập số tài khoản",
    "账户名义": "Tên tài khoản",
    "请输入账户名义": "Vui lòng nhập tên tài khoản",
    "账户名义（片假名）": "Tên tài khoản (katakana)",
    "请输入账户名义（片假名）": "Vui lòng nhập tên tài khoản (katakana)",
      "银行名": "Tên ngân hàng",
  "请输入银行名": "Vui lòng nhập tên ngân hàng"
  };
  