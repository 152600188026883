export default {
    // 通用
    common: {
      app_name: "",
      tab_bar: {
        tab_home: "ホーム",
        tab_reserve: "予約",
        tab_concubine: "妃を選ぶ",
        tab_video: "ビデオ",
        tab_mine: "マイページ",
      },
      view_more: "もっと見る",
      play: "再生",
      finished_text: "もうありません",
      description: "データがありません！",
      Details: "詳細",
      disabled: "機能が無効化されています！",
      offline: "アカウントはオフラインです",
      Sure: "確定",
      Cancel: "キャンセル",
      notset: "未設定",
      Hasbeenset: "設定済み",
      logout: "アボート",
      currency: "円",
      submit: "送信",
      save: "保存",
      success: "完了",
    },
    login: {
      login: "ログイン",
      forget_pwd: "パスワードを忘れましたか？",
      to_reg: "ユーザー名なし",
      inp_username: "ユーザー名",
      inp_pwd: "パスワード",
    },
    register: {
      title: "登録する",
      inp_user: "ユーザー名",
      inp_pass: "パスワード",
      inp_code: "招待コード",
      agreement: '"アカウント開設契約"の条項を理解し、同意します',
      reg_btn: "登録する",
      click_agreement: "下のアカウント開設契約にチェックを入れてください！",
    },
    game: {
      title: "予約ホール",
      all: "すべて",
      xzmoney: "予約済み",
      create_time: "予約時間",
      update_time: "決済時間",
      Gamerules: "ゲームルール",
      gametip: "ゲームのヒント",
      gamedetial: "選択可能な数値の中から番号を選んで賭けます",
      Winninginstructions: "当選説明",
      Winningtip: "3つの当選番号の合計が11〜18は大;合計が3〜10は小です。",
      Bettingexample: "賭けの例",
      Bettingexampletip: "賭けのプラン：小;当選番号：123、つまり小です",
      missionlist: "タスクリスト",
      AvailableBalance: "口座残高：",
      numberselection: "現在の番号選択",
      Amountperbet: "1回あたりの金額",
      Pleaseentertheamount: "金額を入力してください",
      total: "合計",
      total1: "合計",
      note: "注",
      Clearorder: "注文をクリア",
      submission: "確認して送信",
      Issue: "履歴",
      Winningnumbers: "結果",
      Notselected: "未選択",
      Wrongamount: "金額が間違っています！",
      selectanumber: "番号を選択してください",
      Pleasefillintheamount: "金額を入力してください！",
      servicetorecharge: "残高不足です。カスタマーサービスにお問い合わせください！",
      Unselected: "未選択",
      thistask: "このタスクを受け取るには管理者に連絡してください！",
      Thelotterydraw: "抽選に成功しました。期号：",
      Tasktype: "タスクタイプ",
      timeleft: "購入時間が残っています",
    },
    //   ホーム
    home: {
      rec_tasks: "認証エリア",
      pupu_rank: "人気ランキング",
      rec_hot: "ホットな推奨",
      getting: "取得中......",
      refresh_successful: "リフレッシュ成功",
    },
    //   マイページ
    mine: {
      check_in: "毎日チェックイン",
      check_in_box_title: "今月は{dayNum}日チェックインしました",
      check_in_toast: "今日チェックイン済み",
      check_in_tips_title: "報酬ルール：",
      check_in_tips_1: "プラットフォームクラブの会員全員に、[LAUXANH] が特別協賛する特別なデート手当があり、3 日連続でサインインすると、クラブから 30,000 円の報酬と無料ホテル割引クーポンがプレゼントされます。",
      check_in_tips_2: "クラブ会員のみが利用可能となります",
      check_in_tips_3: "3：連続して7日働くと、1人の女の子と無料でデートできます（選択可能）。",
      cardtitle: "受取カードを入力",
      inp_cardtitle: "受取カード情報を入力してください",
      lab_bankid: "銀行口座番号",
      inp_bank: "銀行名",
      bindCardnote:
        "尊敬するユーザー様、ご自身の資金の安全を確保するために、実名と出金パスワードを設定してください。名前が口座名と一致しない場合、出金はできません",
      bindCard: "カードをバインドする",
      inp_bankid: "銀行口座番号を入力してください",
      select_bank: "銀行を選択してください",
      gamerecord: "ログ",
      Recharge: "チャージ",
      withdraw: "引き出し申請",
      Mypurse: "マイウォレット",
      balance: "残高",
      personalreport: "個人レポート",
      Accountdetails: "銀行カード",
      PersonalCenter: "個人センター",
      announcement: "お知らせ",
      onlineservice: "オンラインカスタマーサービス",
      enter: "タスクリストを完了した後に入ってください",
      servicerecharge: "カスタマーサービスにチャージしてください",
      loginRegister: "ログイン/登録",
      logintip: "ログインすると、より多くのサービスが利用できます！",
      Information: "基本情報",
      herderImg: "プロフィール画像",
      selectimg: "プロフィール画像を選択",
      actualname: "本名",
      sex: "性別",
      male: "男",
      female: "女",
      unknown: "不明",
      bindinginformation: "バインディング情報",
      bound: "バインド済み",
      none: "なし",
      cardinformation: "受取カード情報",
      addpaymentcard: "受取カードを追加",
      cardtips: "ヒント：大手商業銀行をバインドしてください。変更が必要な場合は、オンラインカスタマーサービスに連絡してください。",
      Setname: "名前を設定してから銀行カードをバインドしてください！",
      SetPayPassword: "出金パスワードを設定してから銀行カードをバインドしてください！",
      gendermodification: "性別変更",
      setup: "設定",
      Basicinformation: "基本情報設定",
      loginpassword: "ログインパスワード",
      fundpassword: "資金パスワード",
      withdrawalpassword: "出金パスワードが設定されました。変更が必要な場合はカスタマーサービスに連絡してください",
      Withdrawalcenter: "出金センター",
      Withdrawalsrecord: "出金記録",
      withdrawMoney: "出金金額 (円)",
      withdrshowPopovermin: "1.単一の制限：最低",
      withdrshowPopovermax: "最高",
      widtdrnum: "2.出金回数：1日に最高出金回数",
      withdrsucces: "3.入金時間：一般的に5分以内に入金され、最短2分以内に入金されます",
      Limitdescription: "制限の説明",
      Withdrawimmediately: "今すぐ出金",
      correctamount: "正しい金額を入力してください",
      Setfundpassword: "資金パスワードを設定してください",
      enterthefundpassword: "資金パスワードを入力してください",
      enterthefundpasswordagain: "資金パスワードを再度入力してください",
      Pleasefillinthecomplete: "すべてを入力してください",
      twicepassword: "2回のパスワードが一致しません！",
      Changerealname: "本名を変更",
      Name: "名前",
      pleaseenteryourrealname: "本名を入力してください",
      changeralnametip: "アカウントの安全のため、本名はバインドした銀行口座の名前と一致する必要があります",
      Donotrepeatsettings: "重複設定しないでください！",
      Changeloginpassword: "ログインパスワードを変更",
      OldPassword: "旧パスワード",
      newpassword: "新パスワード",
      inp_OldPassword: "旧パスワードを入力してください",
      inp_newpassword: "新パスワードを入力してください",
      inp_newpassword1: "新パスワードを再度入力してください",
      connect: "連絡",
      servicelistItemInfoText: "24時間365日、誠心でサービスを提供します",
      personalreporttitle: "利益額（円）",
      personalreporttip: "利益計算式 : 当選額 - タスク額",
      personalreportplay_money: "タスク額",
      personalreportrecharge: "入金額",
      personalreportwithdrawal: "出金額",
      personalreportwin_money: "当選額",
      inp_name: "本名を入力してください",
    
      rechargecenter: "入金センター",
      rechargerecord: "入金記録",
      banktype: "銀行カード",
      Depositamount: "入金額",
      depositamount: "一回の最大入金額は",
      minimumdepositamount: "一回の最低入金額は",
      pleasecontact: "問題がある場合はご連絡ください",
      next: "次へ",
      bankinfo: "以下は送金先の銀行口座情報です",
      transferinformation: "送金情報",
      copy: "コピー",
      copysuccessfully: "コピー成功",
      copyerr: "コピー失敗、手動でコピーしてください。",
      uprechargeimg: "送金画像をアップロード",
      selectfileimg: "最初に画像を選択してください",
      pleaseinpmoney: "正しい入金額を入力してください",
      submiterr: "提出失敗",
      rechargetsuccess: "入金申請が完了しました。入金申請が成功しました",
      Amount: "金額",
      illustrate: "説明",
      Submissiontime: "提出時間",
      Reviewtime: "審査時間",
      setlang: "言語設定",
      bank_name : "銀行名",
      branch_name : "支店名称",
      branch_code : "支店番号",
      transaction_type : "取引種類",
      account_number : "口座番号",
      account_holder_name : "口座名義",
      account_holder_name_katakana : "口座名義 (カタ)",
    },
    //   予約
    reserve: {},
    //   選妃
    concubine: {
      choosetitle: "妃を選ぶ",
      city: "都市一覧",
      cityinfo: "このプラットフォームは最もリアルな外部サービス+ビジネス伴遊+同市の興奮を提供し、各ユーザーの個人プライバシーを保護するため、顧客は受付係またはプラットフォームの上級会員の推薦によりのみ加入できます。",
      processprice: "プロセス価格",
      rigtitle1: "どのようなリソースがありますか？",
      rigtitle2: "サービス範囲は？",
      rigcontent1: "インフルエンサー、モデル、キャビンアテンダント、若いモデル、大学生、思い浮かぶものがあれば、このプラットフォームで不可能なことはありません",
      rigcontent2: "同市での無料のデート、任意の場所に全国的に展開、国内の一二線都市には必ずあり、三線都市でも受付係に連絡して予約できます。",
      rigcontent3: "このプラットフォームは最もリアルな外部サービス+ビジネス伴遊+同市の興奮を提供し、各ユーザーの個人プライバシーを保護するため、顧客は受付係またはプラットフォームの上級会員の推薦によりのみ加入できます。",
      Contactanadvisor: "顧問または受付係に連絡してください",
    },
    //   ビデオ
    video: {
      title: "ビデオシアター",
      videoInfocount: "回再生",
      Popularrecommendations: "人気の推奨",
      rechargevideo: "動画を見るには入金してください！",
    },
    "认证": "認証",
    "视频认证": "ビデオ認証",
    "评分": "スコア",
    "身高": "身長",
    "地域": "エリア",
    "降落伞": "パラシュート",
    "现在就答应她": "今すぐ彼女と約束",
    "是的": "はい",
    "不是": "いいえ",
    "胸围": "胸囲",
    "请联系管理员": "管理者に連絡してください",
    "提交成功": "送信成功",
    "顶部": "トップ",
    "支店名称": "支店名",
    "请输入支店名称": "支店名を入力してください",
    "支店号码": "支店番号",
    "请输入支店号码": "支店番号を入力してください",
    "交易类型": "取引種類",
    "请输入交易类型": "取引種類を入力してください",
    "账户号码": "口座番号",
    "请输入账户号码": "口座番号を入力してください",
    "账户名义": " 口座名義",
    "请输入账户名义": " 口座名義を入力してください",
    "账户名义（片假名）": "口座名義 (カタ)",
    "请输入账户名义（片假名）": "口座名義 (カタ)を入力してください",
      "银行名": "銀行名",
  "请输入银行名": "銀行名を入力してください",
  //合约
  "质押":"ステーキング",
  "请先设置提现密码":"最初に出金パスワードを設定してください" ,
  "个人":"個人",
  "发行单价":"発行単価",
  "标记成功" :"マーク付けに成功しました" ,
  "操作成功" :"操作は成功しました" ,
  "全球公司拥有多种功能，使其成为买卖数字资产的理想场所" :"グローバル企業は複数の機能を備えているため、デジタル資産を売買するのに理想的な場所となっています。" ,
  "建仓" :"ポジションを構築する" ,
  "会员列表" :"メンバー一覧" ,
  "新币认购记录" :"新しい Perpetua サブスクリプション レコード" ,
  "充值记录" :"リチャージ記録" ,
  "提现记录" :"出金記録" ,
  "发送通知" :"通知を送信" ,
  "真实姓名" :"本名" ,
  "请输入真实姓名" :"本名を入力してください" ,
  "正在结算中" :"請求" ,
  "继续下单" :"認証中です" ,
  "完成下单":"残高を調べます",
  "您的账户已被禁止交易，请联系客服":"あなたのアカウントは取引が禁止されています。カスタマーサービスにご連絡ください。",
  "体验合约详情" :"取引体験詳細" ,
  "体验金" :"ゴールドを体験" ,
  "体验金余额不足" :"お試し金残高不足" ,
  "体验金下单" :"お試し金で注文する" ,
  "体验订单建仓成功" :"注文開始の成功を体験する" ,
  "体验订单建仓失败" :"注文のオープンに失敗しました" ,
  "体验记录" :"体験記録" ,
  "结算倒计时" :"決済カウントダウン" ,
  "倒计时" :"カウントダウン" ,
  "合约体验订单" :"契約体験注文" ,
  "收益比例" :"所得比率" ,
  "当前收益" :"現在の収入" ,
  "预估总收益" :"推定総所得" ,
  "立即参与" :"今すぐ参加してください" ,
  "登陆时间" :"ログイン時間" ,
  "二代会员" :"2期生メンバー" ,
  "市价交易" :"市場取引" ,
  "名称" :"名前" ,
  "已达到限购数量" :"購入制限に達しました" ,
  "已结算" :"決済済み" ,
  "待结算" :"解決予定" ,
  "请输入转账额度" :"送金限度額を入力してください" ,
  "自定义金额" :"認証金額" ,
  "选择投资金额" :"投資額を選択" ,
  "请输入金额" :"金額を入力してください" ,
  "不能小于最低投资额度" :"最低投資限度額を下回ることはできません" ,
  "不能大于最大投资额度" :"最大投資額を超えることはできません" ,
  "请选择或输入投入金额" :"入力金額を選択または入力してください" ,
  "用户名或密码错误" :"ユーザー名またはパスワードが間違っています" ,
  "该账号不是代理" :"このアカウントはエージェントではありません" ,
  "代理系统" :"エージェントシステム" ,
  "输入代理账号" :"エージェント アカウントを入力してください" ,
  "代理中心" :"代理店センター" ,
  "初始化搜索" :"検索の初期化" ,
  "邮箱账号" :"メールアカウント" ,
  "请输入邮箱账号" :"メール アカウントを入力してください" ,
  "会员账号" :"メンバーアカウント" ,
  "注册IP" :"IPを登録" ,
  "推荐人" :"推薦者" ,
  "认证状态" :"認証ステータス" ,
  "合约建仓订单列表" :"契約施工順序一覧" ,
  "合约平仓订单列表" :"取引決済注文一覧" ,
  "请选择正确的充值通道网络，否则资产将不可找回" :"正しいリチャージ チャネル ネットワークを選択してください。そうでない場合、アセットは取得されません。" ,
  "已读" :"読む" ,
  "未读" :"未読" ,
"合约详情" :"契約内容" ,
"合约金额" :"契約金額" ,
"合约时长" :"契約期間" ,
  "平仓单价" :"成約単価" ,
"分钟" :"分" ,
"买涨" :"同じ街認証" ,
"买跌" :"同じ国認証" ,
"合约状态" :"契約状況" ,
"结算成功" :"決済成功" ,
"未结算" :"未確定" ,
"建仓单价" :"オープニング単価" ,
"建仓时间" :"オープン時間" ,
"登录" :"ログイン" ,
"验证码" :"認証コード" ,
  "平仓时间" :"閉店時間" ,
  "盈亏金额" :"損益金額" ,
  "合约记录" :"契約記録" ,
  "暂时没有充值记录" :"当面リチャージ記録はありません" ,
  "交易对" :"取引ペア" ,
  "投入金额" :"投入量" ,
  "交割合约" :"認証センター" ,
  "最低" :"最低" ,
  "最高" :"最高" ,
  "量" :"金額" ,
  "确认订单" :"注文を確認する" ,
  "订单类型" :"注文タイプ" ,
  "收益率" :"収量" ,
  "投资金额" :"投資額" ,
  "账户余额" :"アカウント残高" ,
  "方向" :"方向" ,
  "现价" :"現在の価格" ,
  "金额" :"金額" ,
  "全部" :"すべて" ,
  "没有获取数据" :"データは取得されませんでした" ,
  "首页" :"ホーム" ,
  "交易" :"取引" ,
  "永续" :"持続可能" ,
  "矿机" :"マイナー" ,
  "合约" :"契約" ,
  "交割" :"配送" ,
  "资产" :"資産" ,
  "请选择结算时间" :"決済時間を選択してください" ,
  "请选择投入金额" :"入力金額を選択してください" ,
  "缺少重要参数" :"重要なパラメータが欠落しています" ,
  "发币时间" :"発行時間" ,
  "公告管理" :"お知らせ管理" ,
  "没有公告发布" :"発表はありません" ,
  "消息管理" :"メッセージ管理" ,
  "完成" :"完了" ,
  "通知" :"お知らせ" ,
  "没有通知记录" :"通知記録がありません" ,
  "密码" :"パスワード" ,
  "为了您的资金安全，登陆密码修改后24小时以内不允许提现" :"資金の安全のため、ログイン パスワードの変更後 24 時間以内の出金は許可されていません" ,
  "旧密码" :"古いパスワード" ,
  "新密码" :"新しいパスワード" ,
  "旧提现密码" :"古い出金パスワード" ,
  "新提现密码" :"新しい出金パスワード" ,
  "您尚未设置提现密码,请先前往设置!" :"出金パスワードがまだ設定されていません。最初に設定に行ってください!" ,
  "去设置" :"設定に移動" ,
  "提现密码":"退会パスワード",
  "设置提现密码" :"出金パスワードを設定する" ,
  "修改提现密码" :"出金パスワード変更" ,
  "请输入旧密码" :"古いパスワードを入力してください" ,
  "请输入新密码" :"新しいパスワードを入力してください" ,
  "请输入确认密码" :"確認用パスワードを入力してください" ,
  "提交" :"送信" ,
  "两次输入的密码不一致" :"2 回入力されたパスワードは一貫性がありません" ,
  "邀请" :"招待" ,
  "我的邀请码" :"私の招待コード" ,
  "复制邀请链接" :"招待リンクをコピー" ,
  "复制成功" :"コピーに成功しました" ,
  "语言" :"言語" ,
  "帮助中心" :"ヘルプセンター" ,
  "新通知" :"新着通知" ,
  "修改密码" :"パスワードを変更" ,
  "提币地址" :"出金アドレス" ,
  "分享推荐" :"推奨事項を共有する" ,
  "请不要把密码，短信验证码等信息透露给任何人,客服人员也不会向您索要账号信息" :"パスワード、SMS 認証コード、その他の情報を誰にも開示しないでください。また、カスタマー サービス スタッフがアカウント情報を尋ねることはありません。" ,
  "退出" :"終了" ,
  "认购项目详情" :"サブスクリプションプロジェクトの詳細" ,
  "认购币种" :"サブスクリプション通貨" ,
  "开始时间" :"開始時刻" ,
  "结束时间" :"終了時刻" ,
  "发行总量" :"発行総額" ,
  "认购单价" :"サブスクリプション単価" ,
  "锁仓时间" :"ロック時間" ,
  "天" :"日" ,
  "参与数量" :"参加数量" ,
  "单次最低" :"単一の最小値" ,
  "请输入价格" :"価格を入力してください" ,
  "需要支付" :"支払う必要があります" ,
  "可用" :"利用可能" ,
  "请先登陆" :"最初にログインしてください" ,
  "项目说明" :"プロジェクトの説明" ,
  "新币认购" :"新しい Perpetua サブスクリプション" ,
  "暂时没有认购项目" :"当面の間、定期購読プロジェクトはありません" ,
  "全部认购" :"すべてのサブスクリプション" ,
  "冻结中" :"凍結" ,
  "已解冻" :"解凍" ,
  "暂时没有记录" :"当面の記録はありません" ,
  "项目" :"プロジェクト" ,
  "支付金额" :"支払金額" ,
  "解冻日期" :"解凍日" ,
  "重置密码" :"パスワードをリセット" ,
  "图形验证码" :"グラフィック検証コード" ,
  "请输入图形验证码" :"グラフィック確認コードを入力してください" ,
  "换一张" :"1 つ変更" ,
  "邮箱验证码" :"メール認証コード" ,
  "请输入邮箱验证码" :"メール認証コードを入力してください" ,
  "获取验证码" :"確認コードを取得" ,
  "邮箱格式不正确" :"メールボックスの形式が正しくありません" ,
  "请输入密码" :"パスワードを入力してください" ,
  "您已经多次注册" :"複数回登録しています" ,
  "秒后再发送" :"数秒で送信" ,
  "注册" :"登録" ,
"请输入验证码" :"認証コードを入力してください" ,
"忘记密码" :"パスワードを忘れた場合" ,
"公告中心" :"アナウンス センター" ,
"登录或注册" :"ログインまたは登録" ,
"邀请码" :"招待コード" ,
"请输入邀请码" :"招待コードを入力してください" ,
"选填" :"オプションの記入" ,
"注册即表示同意" :"登録は同意を意味します" ,
"用户服务协议" :"ユーザーサービス規約" ,
"请输入邮箱" :"メールアドレスを入力してください" ,
"矿机市场" :"鉱山機械市場" ,
"总览" :"概要" ,
"独资" :"個人事業主" ,
"上市时间" :"市場投入までの時間" ,
"产出币种" :"出力通貨" ,
"立即购买" :"今すぐ購入" ,
"矿机单价" :"マイニングマシンの単価" ,
"矿机产权" :"マイニングマシンの財産権" ,
"矿机类型" :"マイナータイプ" ,
"独资机型" :"単独所有モデル" ,
"共享机型" :"共有モデル" ,
"暂时没有矿机" :"当分マイナーはいません" ,
"购买时间" :"購入時間" ,
"到期时间" :"有効期限" ,
"停止" :"停止" ,
"过期" :"期限切れ" ,
"收益类型" :"収入の種類" ,
"按产值" :"出力値別" ,
"恒定币量" :"一定のコイン数量" ,
"日产值" :"日次値" ,
"日产币量" :"毎日のコイン生産" ,
"矿机详情" :"マイナーの詳細" ,
"产出说明" :"出力の説明" ,
"按币量" :"通貨別" ,
"矿机说明" :"マイナーの説明" ,
"购买条件" :"購入条件" ,
"最低持仓" :"最小位置" ,
"平台币" :"プラットフォームコイン" ,
"要求直推" :"直接プッシュが必要です" ,
"人" :"人" ,
"产币冻结" :"凍結" ,
"否" :"いいえ" ,
"结算说明" :"決済説明" ,
"定期自动结算" :"定期自動決済" ,
"选择份额" :"共有を選択" ,
"共享链接" :"リンクを共有" ,
"修改份额后需重新复制链接" :"共有を変更した後、リンクを再度コピーします" ,
"复制" :"コピー" ,
"购买" :"購入" ,
"剩余份额" :"残余シェア" ,
"矿机收益曲线" :"マイニング マシンの収入曲線" ,
"总收益" :"総収益" ,
"暂时没有收益记录" :"当面収入記録はありません" ,
"交易详情" :"取引詳細" ,
"出售" :"売る" ,
"时间" :"時間" ,
"委托价" :"手数料価格" ,
"交易额" :"取引金額" ,
"成交总价" :"取引価格合計" ,
"成交均价" :"平均取引価格" ,
"成交量" :"音量" ,
"手续费" :"サービス料" ,
"手续费比例" :"サービス料率" ,
"USDT市场" :"認証 マーケット" ,
"最新价格" :"最新価格" ,
"24h涨跌" :"24 の上昇と下降" ,
"全部委托" :"すべてのコミッション" ,
"历史记录" :"歴史的記録" ,
"成交明细" :"取引詳細" ,
"已完成" :"完了" ,
"取消" :"キャンセル" ,
"实际成交" :"実際のトランザクション" ,
"充币" :"デポジット" ,
  "划转USDT" :"USDTの転送" ,
"提币" :"出金" ,
"分享" :"共有" ,
"客服" :"カスタマーサービス" ,
"支持多币种、多时间类型" :"複数の通貨と複数の時間タイプをサポート" ,
"智能挖矿" :"インテリジェント マイニング" ,
"矿机出租、共享矿机" :"マイニングマシンレンタル、マイニングマシン共用" ,
"涨跌幅" :"変動幅" ,
"永续合约" :"永久契約" ,
"最近成交" :"最近の取引" ,
"价格" :"価格" ,
"数量" :"数量" ,
"买入" :"購入" ,
"卖出" :"売る" ,
"限价委托" :"指値注文" ,
"市价委托" :"成行注文" ,
"按市场最优价" :"最良の市場価格で" ,
"输入数量" :"数量を入力" ,
"没有委托订单" :"委任された注文はありません" ,
"提币地址管理" :"出金アドレス管理" ,
"添加" :"追加" ,
"去交易" :"取引する" ,
"财务记录" :"財務記録" ,
"合约建仓" :"契約ビル" ,
"合约平仓" :"契約締結" ,
"状态" :"状態" ,
"市价" :"市場価格" ,
"搜索您需要的币种" :"必要な通貨を検索" ,
"热门币种" :"ホット通貨" ,
"币种列表" :"通貨一覧" ,
"不支持充币" :"デポジットをサポートしていません" ,
"充币记录" :"充電記録" ,
"币种" :"通貨" ,
"涨幅" :"増加" ,
"24小时量" :"24時間の出来高" ,
"确认中" :"確認中" ,
"原路返回" :"同じ道を戻る" ,
"充币网络" :"コインデポジットネットワーク" ,
"充币地址" :"充電アドレス" ,
"复制地址" :"アドレスをコピー" ,
"小于最小金额的充值将不会上账且无法退回" :"最低金額未満のチャージはクレジットされず、返金できません" ,
"请勿向上述地址充值任何非" :"以外は充電しないでください" ,
"否则资产将不可找回" :"そうしないと、資産は回復されません" ,
"最小充值金额" :"最低チャージ金額" ,
"您的充值地址不会经常改变，可以重复充值；如有更改，我们会尽量通过网站公告或邮件通知您" :"リチャージアドレスは頻繁に変更されず、繰り返しリチャージできます。変更があった場合は、ウェブサイトのお知らせや電子メールでお知らせするよう最善を尽くします。" ,
"转账金额" :"送金額" ,
"请输入转账金额" :"振込金額を入力してください" ,
"上传转账凭证" :"振替伝票をアップロード" ,
"请上传转账凭证" :"振替伝票をアップロードしてください" ,
"上传失败" :"アップロードに失敗しました" ,
"资产列表" :"アセットリスト" ,
"由于资金统计存在延时，账户余额不一定是并非最新余额，如需准确余额请到相关账户页面进行查询" :"ファンドの統計の遅れにより、口座残高は必ずしも最新の残高ではありません。正確な残高が必要な場合は、該当する口座のページにアクセスしてご確認ください。" ,
"确定" :"OK" ,
"添加提币地址" :"出金アドレスを追加" ,
"提币网络" :"コインネットワークを引き出す" ,
"输入或长按粘贴地址" :"貼り付けアドレスを入力または長押し" ,
"备注" :"備考" ,
"请输入备注" :"コメントを入力してください" ,
"保存" :"保存" ,
"请输入提币地址" :"出金アドレスを入力してください" ,
"参数错误" :"パラメータエラー" ,
"没有保存地址" :"保存されたアドレスがありません" ,
"添加地址" :"アドレスを追加" ,
"可提币列表" :"出金リスト" ,
"类型" :"タイプ" ,
"普通提币" :"通常出金" ,
"实际到账" :"実際の到着" ,
"未通知" :"通知されていません" ,
"提币记录" :"出金記録" ,
"暂时没有提币记录" :"現時点では Perpetua の出金記録はありません" ,
"输入提币数量" :"出金数量を入力" ,
"最小提币数量" :"最低出金数量" ,
"小于最小金额的提币将不会到账且无法退回" :"最低金額未満の出金は受け付けず、返金もできません" ,
"为保障资金安全，当您账户安全策略变更，密码修改，我们会对提币进行人工审核，请耐心等待工作人员电话或邮件联系." :"資金の安全性を確保するため、アカウントのセキュリティ ポリシーが変更されたり、パスワードが変更されたりした場合、出金の手動審査が行われます。スタッフから電話または電子メールで連絡されるまで、しばらくお待ちください。" ,
"请务必确认电脑及浏览器安全，防止信息被篡改或泄露" :"情報の改ざんや開示を防ぐために、コンピューターとブラウザが安全であることを確認してください。" ,
"地址" :"住所" ,
"关闭" :"閉じる" ,
"地址管理" :"アドレス管理" ,
"暂时没有已保存地址" :"一時的に保存されたアドレスがありません" ,
"去添加" :"追加するには" ,
"请输入正确的数量" :"正しい数量を入力してください" ,
"不能低于最小提币值" :"最低出金額より低くすることはできません" ,
"非法操作" :"不正な操作" ,
"请选择投资额度" :"投資枠を選択してください" ,
"USDT余额不足" :"米ドル残高が不十分です" ,
"建仓成功" :"倉庫建設の成功" ,
"建仓失败" :"倉庫の構築に失敗しました" ,
"网络错误" :"ネットワーク エラー" ,
"删除成功" :"削除に成功しました" ,
"没有记录" :"記録なし" ,
"标注成功" :"ラベルが成功しました" ,
"全部已读" :"すべて読んだ" ,
"您输入的信息有误" :"入力した情報が正しくありません" ,
"旧密码不正确" :"古いパスワードが間違っています" ,
"密码修改成功" :"パスワードが正常に変更されました" ,
"密码修改失败" :"パスワードの変更に失敗しました" ,
"认购未开始" :"サブスクリプションが開始されていません" ,
"认购已结束" :"サブスクリプションは終了しました" ,
"禁止认购" :"サブスクリプションなし" ,
"请输入认购数量" :"サブスクリプション数量を入力してください" ,
"不能小于最低认购量" :"最低サブスクリプション金額未満にすることはできません" ,
"不能高于最高认购量" :"最大サブスクリプション金額を超えてはなりません" ,
"已超出个人认购上限" :"個人のサブスクリプション制限を超過しました" ,
"超出发行总量" :"発行総額超過" ,
"账户余额不足" :"口座残高が不足しています" ,
"认购" :"サブスクリプション" ,
"认购成功" :"サブスクリプションが成功しました" ,
"认购失败" :"サブスクリプションの失敗" ,
"一代会员认购奖励" :"世代メンバーシップ サブスクリプション特典" ,
"二代会员认购奖励" :"二代目会員登録特典" ,
"三代会员认购奖励" :"三世代会員定期購読特典" ,
"邮箱验证码错误" :"メールボックス確認コード エラー" ,
"邮箱未注册" :"メールボックスが登録されていません" ,
"登陆密码重置成功" :"ログインパスワードのリセットに成功しました" ,
"密码重置失败" :"パスワードのリセットに失敗しました" ,
"图形验证码错误" :"グラフィック検証コード エラー" ,
"用户不存在" :"ユーザーが存在しません" ,
"登录密码错误" :"ログインパスワードエラー" ,
"你的账号已冻结请联系管理员" :"あなたのアカウントは凍結されました。管理者に連絡してください。" ,
"邮箱登录" :"メールボックス ログイン" ,
"登录成功" :"ログインに成功しました" ,
"登录失败" :"ログインに失敗しました" ,
"用户名已存在" :"ユーザー名はすでに存在します" ,
"注册成功" :"登録に成功しました" ,
"注册失败" :"登録に失敗しました" ,
"验证码发送成功" :"検証コードが正常に送信されました" ,
"验证码发送失败" :"認証コードの送信に失敗しました" ,
"不是共享矿机" :"共有マイナーではありません" ,
"选择的占比份额不正确" :"間違った比率が選択されました" ,
"矿机暂停出售" :"マイニングマシンの販売停止" ,
"售机已售罄" :"マシンは売り切れました" ,
"持有平台币额度不足" :"プラットフォーム通貨の保有量が不足しています" ,
"直推人数未达要求" :"ダイレクトプッシャーの数が要件を満たしていません" ,
"购机奖励" :"購入報酬" ,
"委托订单不存在" :"委任された注文は存在しません" ,
"订单已撤消" :"注文がキャンセルされました" ,
"订单撤消失败" :"注文のキャンセルに失敗しました" ,
"未开市" :"公開市場ではない" ,
"余额不足" :"残高不足" ,
"请输入出售额度" :"販売ノルマを入力してください" ,
"请输入限价价格" :"制限価格を入力してください" ,
"订单委托成功" :"注文の委任が成功しました" ,
"订单委托失败" :"注文の委任に失敗しました" ,
"永续合约出售" :"永久トランザクションセール" ,
"出售失败" :"販売失敗" ,
"请输入买入金额" :"購入金額を入力してください" ,
"出售成功" :"販売成功" ,
"永续合约购买" :"Perpetua トランザクション購入" ,
"交易成功" :"取引が成功しました" ,
"交易失败" :"トランザクションが失敗しました" ,
"请输入正确的额度" :"正しい金額を入力してください" ,
"不能高于最大提币值" :"最大出金額を超えることはできません" ,
"提交失败" :"送信に失敗しました" ,
"添加成功" :"正常に追加されました" ,
"添加失败" :"追加に失敗しました" ,
"提币地址不存在" :"出金アドレスが存在しません" ,
"删除失败" :"削除に失敗しました" ,
"请输入正确充值数量" :"正しいリチャージ数量を入力してください" ,
"低于最低额度" :"最小値を下回る" ,
"凭证提交成功" :"バウチャーは正常に送信されました" ,
"凭证提交失败" :"バウチャーの送信に失敗しました" ,
"上传成功" :"アップロードに成功しました" ,
"永续合约限价委托交易" :"無期限契約指値注文取引" ,
"永续合约限价购买委托订单购买成功" :"Perpetua取引における限定価格買取の委託注文の購入が成功しました" ,
"永续合约限价出售委托成交" :"永久契約指値販売委託取引",
"永续合约限价购买委托订单出售成功" :"永久約定価格指値買委託注文の販売が成功しました" ,
"释放冻结收益" :"凍結された収益を解放する" ,
"永续合约限价购买委托成交" :"無期限指値指値買受委託取引",
"您冻结的矿机收益释放成功，可以交易" :"あなたの凍結マイニングマシン収入は正常にリリースされ、取引可能です。" ,
"矿机收益释放冻结" :"マイニングマシンの収入リリースの凍結" ,
"矿机收益" :"マイニングマシンの収入" ,
"今日矿机收益已成功到账，请注册查收" :"本日のマイニングマシン収益を正常に受け取りました。登録して確認してください。" ,
"矿机收益释放" :"マイニング マシンの収益リリース" ,
"合约出售" :"契約販売" ,
"交割合约交易" :"配送契約取引" ,
"交割合约已平仓，请及时加仓" :"配達契約は終了しました。時間内にポジションを増やしてください。" ,
"语言选择" :"言語の選択" ,
"清除全部" :"すべてクリア" ,
"确认密码" :"パスワードの確認" ,
"提币数量" :"出金数量" ,
"选择" :"選択" ,
"账户总资产折合" :"口座総資産換算" ,
"冻结" :"フリーズ" ,
"折合" :"同等" ,
"确认下单" :"認証を提出します" ,
"合约类型" :"契約種類" ,
"矿机收益列表" :"マイニングマシン収益一覧" ,
"购买成功" :"購入に成功しました" ,
"永续合约市场" :"無期限契約市場" ,
"市场" :"市場" ,
"提币审核" :"退会審査" ,
"您的提币申请已通过，请及时查询" :"通貨引き出し申請は期限切れです。時間内にご確認ください。" ,
"您的提币申请被驳回，请联系管理员" :"出金申請は拒否されました。管理者に連絡してください。" ,
"提币驳回，退回资金" :"出金拒否、資金返還" ,
"您的充值金额已到账，请注意查收" :"チャージ金額が届きました。ご確認ください。" ,
"您的充币记录被系统驳回，请联系客服" :"あなたの充電記録はシステムによって拒否されました。カスタマーサービスに連絡してください。" ,
  "您的充币记录被系统驳回 驳回理由" :"あなたの入金記録はシステムによって拒否されました。拒否の理由" ,
"充币审核" :"充電承認" ,
"登陆" :"ログイン" ,
"邮箱" :"メールボックス" ,
"认购上限" :"サブスクリプション制限" ,
"币币市场" :"パーペチュア マーケット" ,
"手机号已绑定" :"携帯電話番号バインド" ,
"不能重复认证" :"認証を繰り返すことはできません" ,
"请上传上传身份证正面" :"ID カードの表面をアップロードしてください" ,
"请上传上传身份证背面" :"ID カードの裏面をアップロードしてください" ,
"认证资料提交成功" :"証明書データが正常に送信されました" ,
"实名资料提成功，耐心等待管理员审核" :"実名データは正常に送信されました。管理者が審査するまでしばらくお待ちください。",
"认证资料提交失败" :"認証データの送信に失敗しました" ,
"实名认证" :"実名認証" ,
"完成实名认证后能够获得相应的权益" :"実名認証完了後、対応する権利利益を取得できます" ,
"手机号码" :"携帯電話番号" ,
"上传身份证正面" :"ID カード表面をアップロード" ,
"上传身份证背面" :"ID カードの裏面をアップロード" ,
"认购驳回" :"サブスクリプションの拒否" ,
"您的认证申请被管理员驳回，请联系管理员" :"認証申請は管理者によって拒否されました。管理者に連絡してください。" ,
"认证审核成功" :"認証監査に成功しました" ,
"您的认证申请已审核成功" :"あなたの認定申請は正常に承認されました" ,
"请先完成实名认证" :"先に実名認証を完了してください" ,
"冻结矿机释放收益" :"凍結マイナーのリリースが進行中" ,
"提币退回" :"出金返品" ,
  "充币成功" :"充電成功" ,
"禁止交易" :"禁止されている取引" ,
"请输入信息内容" :"情報を入力してください" ,
  "发送" :"送信" ,
  "在线" :"オンライン" ,
  "不在线" :"オンラインではありません" ,
  "您好,请问有什么可以帮您?" :"こんにちは、お手伝いしてもいいですか?" ,
  "信息发送失败" :"メッセージの送信に失敗しました" ,
  "在线客服" :"オンラインカスタマーサービス" ,
  //以下内容吸有中英文://以下のコンテンツは中国語と英語の両方で書かれています。,
  "认购数量" :"サブスクリプション数量" ,
  "不要重复购买" :"二度と買わないでください" ,
  "认购资产释放" :"サブスクライブされたアセットのリリース" ,
  "下载" :"ダウンロード" ,
  "下载APP" :"アプリをダウンロード" ,
  "自动识别设备" :"自動識別装置" ,
  "已认证" :"認定済み" ,
  "账户总览" :"アカウント概要" ,
  "安全设置" :"セキュリティ設定" ,
  "身份认证" :"本人認証" ,
  "推荐返佣" :"推薦手数料" ,
  "退出账号" :"アカウントを終了する" ,
  "订单" :"注文" ,
  "永续合约订单" :"永久契約注文" ,
  "合约交易订单" :"約定取引注文" ,
  "认购订单" :"定期購入の注文" ,
  "矿机订单" :"マイナー注文" ,
  "钱包" :"ウォレット" ,
  "账户余额详情" :"口座残高の詳細" ,
  "永续合约委托" :"永久契約委託" ,
  "触发条件" :"トリガー条件" ,
  "限价" :"限定価格" ,
  "限价价格" :"指値価格" ,
  "成交额" :"売上高" ,
  "完成度" :"完全性" ,
  "操作" :"操作" ,
  "建仓金额" :"ポジションをオープンする量" ,
  "周期" :"サイクル" ,
  "用户" :"ユーザー" ,
  "上次登录时间" :"最終ログイン時刻" ,
  "地址备注" :"アドレスメモ" ,
  "转账网络" :"転送ネットワーク" ,
  "删除" :"削除" ,
  "认证信息" :"認証情報" ,
  "实名认证管理" :"実名認証管理" ,
  "修改登陆密码" :"ログインパスワードを変更" ,
  "团队详情" :"チーム詳細" ,
  "三代会员统计" :"三世代の会員統計" ,
  "一代会员" :"世代メンバー" ,
  "二代会员:" :"2期メンバー:" ,
  "三代会员" :"三世代" ,
  "推荐码" :"推奨コード" ,
  "登陆日志" :"ログインログ" ,
  "登陆类型" :"ログインタイプ" ,
  "登陆IP" :"ログインIP" ,
  "我的通知" :"私の通知" ,
  "通知管理" :"通知管理" ,
  "标记已读" :"既読としてマーク" ,
  "全部删除" :"すべて削除" ,
  "通知标题" :"お知らせタイトル" ,
  "内容" :"コンテンツ" ,
  "联系客服" :"カスタマーサービスに連絡する" ,
  "聊天室" :"チャットルーム" ,
  "我的账单" :"私の請求書" ,
  "操作类型" :"操作タイプ" ,
  "操作后余额" :"操作後のバランス" ,
  "操作日期" :"操作日" ,
  "币币全部委托" :"永久の委託" ,
  "币币历史记录" :"永久の歴史" ,
  "合约建仓订单" :"契約構築注文" ,
  "合约平仓订单" :"契約締結注文" ,
  "正常矿机订单" :"通常のマイナー注文" ,
  "过期矿机订单" :"期限切れのマイニング マシン注文" ,
  "认购冻结订单" :"サブスクリプションの凍結注文" ,
  "认购解冻订单" :"サブスクリプションの凍結解除順序" ,
  "委托中" :"委託" ,
  "委托额度" :"委託金額" ,
  "交易限价" :"取引限度額" ,
  "委托时间" :"お任せ時間" ,
  "撤消委托" :"委任を取り消す" ,
  "结单时间" :"発言時間" ,
  "未平仓合约" :"オープンポジション" ,
  "已平仓合约" :"クローズポジション" ,
  "成交单价" :"取引単価" ,
  "产出类型" :"出力タイプ" ,
  "每天产出" :"日次出力" ,
  "每天产出每天等值" :"日産相当額" ,
  "矿机名称" :"マイナー名" ,
  "过期矿机" :"期限切れのマイナー" ,
  "认购记录" :"サブスクリプション レコード" ,
  "项目名称" :"プロジェクト名",
  "单价" :"単価" ,
  "冻结周期" :"凍結サイクル" ,
  "认购时间" :"購読時間" ,
  "已释放" :"リリース済み" ,
  "释放时间" :"リリースタイム" ,
  "钱包总览" :"ウォレットの概要" ,
  "账单" :"請求書" ,
  "数字货币充值" :"デジタル通貨リチャージ" ,
  "选择币种" :"通貨を選択" ,
  "充值网络" :"リチャージネットワーク" ,
  "网络" :"ネットワーク" ,
  "充值数量" :"リチャージ量" ,
  "转账凭证" :"振替伝票" ,
  "温馨提示" :"ウォームリマインダー" ,
  "数字货币提币" :"デジタル通貨の出金" ,
  "充币列表" :"課金リスト" ,
  "充币金额" :"チャージ金額" ,
  "充币时间" :"充電時間" ,
  "提币列表" :"出金リスト" ,
  "提币币种" :"出金通貨" ,
  "提币额度" :"出金限度額" ,
  "提币时间" :"出金時間" ,
  "收益币种" :"収益通貨" ,
  "收益金额" :"収益金額" ,
  "收益日期" :"収益日" ,
  "释放状态" :"リリースステータス" ,
  "未释放" :"リリースされていない" ,
  "矿机收益记录" :"鉱山機械収益記録" ,
  "请输入您的问题" :"質問を入力してください" ,
  "在这儿搜索" :"ここを検索" ,
  "白皮书" :"ホワイトペーパー" ,
  "共享" :"共有" ,
  "我的矿机" :"私のマイナー" ,
  "矿机商城" :"鉱山機械モール" ,
  "收益次数" :"収益時間" ,
  "收益账单" :"収入請求書" ,
  "按产量" :"出力別" ,
  "收益方式" :"収入モード" ,
  "自动收益" :"自動収入" ,
  "正常" :"通常" ,
  "未认证" :"認定されていません" ,
  "我要参与" :"参加したい" ,
  "我的订单" :"私の注文" ,
  "盘口" :"ハンディキャップ" ,
  "行情" :"市場" ,
  "我的钱包" :"私の財布" ,
  "所有权" :"所有権" ,
  "每天等值" :"1 日相当額" ,
  "前往钱包查看资产总余额" :"ウォレットに移動して資産の合計残高を表示します" ,
  "交割合约委托" :"配送契約の委託",
  "账户登录" :"アカウントログイン" ,
  "欢迎回来,使用您的电子邮件登录" :"ようこそ、メールアドレスでサインインしてください" ,
  "立即注册" :"今すぐ登録" ,
  "创建账户" :"アカウントの作成" ,
"使用您的电子邮箱进行注册" :"メール アドレスを使用して登録します" ,
"注册过" :"登録済み" ,
"按产值,每天价值" :"出力値別の 1 日あたりの価値" ,
"购买矿机" :"マイナーを購入" ,
  "买币" :"仮想通貨を購入" ,
  "Bitwise一直以来担任全球加密货币领导者" :"Bitwise は長い間世界的な暗号通貨のリーダーであり続けています" ,
  "24小时交易量" :"24時間取引高" ,
  "立即开始您的加密货币之旅" :"今日から暗号通貨の旅を始めましょう" ,
  "Robit 全球公司拥有多种功能，使其成为买卖数字资产的理想场所" :"Robit Global には、デジタル資産の売買に理想的な場所となるいくつかの機能があります。" ,
  "管理您的资产" :"資産を管理する" ,
  "以高达5倍的杠杆率进行现货交易" :"最大 5 倍のレバレッジでの現物取引" ,
  "信用卡付款" :"クレジットカード決済" ,
  "通过我们的合作伙伴用您的信用卡购买加密货币" :"当社のパートナーを通じてクレジット カードで暗号通貨を購入します" ,
  "安全储存" :"安全な保管場所" ,
  "客户资金存放在专用的多签名" :"クライアントの資金は専用のマルチシグネチャで保持されます" ,
  "冷钱包中.24/7全天候安全监控" :"コールド ウォレット内。24 時間 365 日のセキュリティ監視" ,
  "专用20,000 BTC安全储备金" :"専用の 20,000 BTC 安全リザーブ" ,
  "随处访问" :"どこでもアクセス" ,
  "在我们用于Android和iOS的移动应用上进行24/7全" :"Android および iOS 用のモバイル アプリへの 24 時間 365 日のアクセス" ,
  "天候存款，取款和交易" :"天候による入金、出金および取引" ,
  "公告" :"お知らせ" ,
  "购买加密货币" :"暗号通貨を購入" ,
  "当前委托" :"現在の権限" ,
  "历史委托" :"歴史委員会" ,
  "关于我们" :"私たちについて" ,
  "MSB证书" :"MSB 証明書" ,
  "隐私政策申明" :"プライバシー ポリシー ステートメント" ,
  "服务条款" :"利用規約" ,
  "新币特价抢先购" :"新コインスペシャル早期アクセス" ,
  "为用户提供低成本抢首发项目的投资机会" :"初めてのプロジェクトを獲得するための低コストの投資機会をユーザーに提供します" ,
  "即享挖矿奖励，随存随取，灵活操作" :"すぐにマイニング報酬を享受でき、いつでも入出金でき、柔軟な運用が可能" ,
  "暂无订单" :"まだ注文はありません" ,
  "查看更多" :"もっと見る" ,
  "欢迎来到" :"ようこそ" ,
  "抢购区" :"スナップアップエリア" ,
  "我要买币" :"コインを買いたい" ,
  "交割合约记录" :"納品契約記録" ,
  "选择周期" :"認証デ-タ" ,
  "选择金额" :"金額を選択" ,
  "预计盈利" :"推定利益" ,
  "请输入出售数量" :"販売数量を入力してください" ,
  "即享挖矿奖励" :"インスタントマイニング報酬" ,
  "随存随取" :"いつでも保存" ,
  "灵活操作" :"柔軟な運用" ,
  "买入量" :"ボリュームを購入" ,
  "邮箱reg" :"メールボックス登録" ,
  "手机" :"モバイル" ,
  "请完成安全验证" :"セキュリティ検証を完了してください" ,
  "向右滑动填充拼图" :"右にスワイプしてパズルを埋める" ,
  "我的邀请" :"私の招待状" ,
  "MSB认证" :"MSB 認証" ,
  "充值" :"リチャージ" ,
  "国家" :"国" ,
  "地区" :"面積" ,
  "姓名" :"名前" ,
  "上传证件反面" :"ドキュメントの裏面をアップロード" ,
  "验证码错误" :"認証コードエラー" ,
  "请输入手机号码" :"携帯電話番号を入力してください" ,
  "请输入账号" :"口座番号を入力してください" ,
  "账号" :"アカウント" ,
  "重复密码不正确" :"繰り返しのパスワードが間違っています" ,
  "认证类型" :"認証タイプ" ,
  "上传证件正面" :"文書の表面をアップロード" ,
  "护照" :"パスポート" ,
  "驾驶证" :"運転免許証" ,
  "SSN" :"SSN" ,
  "身份ID" :"アイデンティティ ID" ,
  "请填写地区" :"地域を入力してください" ,
  "请填写真实姓名" :"本名を入力してください" ,
  "请选择认证类型" :"認証タイプを選択してください" ,
  "审核中" :"審査中" ,
  "已设置" :"設定されています" ,
  "未设置" :"未設定" ,
  "密码设置" :"パスワード設定" ,
  "如忘记旧密码无法修改提现密码，请第一时间联系在线客服核实身份后修改" :"古いパスワードを忘れて出金パスワードを変更できない場合は、できるだけ早くオンライン カスタマー サービスに連絡して本人確認を行ってから変更してください。",
  "正在加载中" :"読み込み中" ,
  "再试一次" :"もう一度お試しください" ,
  "下载App" :"アプリをダウンロード" ,
  "注册账号" :"アカウントを登録する" ,
  "买入价" :"購入価格" ,
  "卖出价" :"販売価格" ,
  "卖出量" :"販売量" ,
  "独资矿机" :"単独所有のマイニング マシン" ,
  "共享矿机" :"共有マイニング マシン" ,
  "我要购买" :"買いたい" ,
  "请填写提现密码" :"出金パスワードを入力してください" ,
  "提现密码错误,请重新填写" :"出金パスワードが間違っています。もう一度入力してください。" ,
  "请前往地址管理添加" :"追加するにはアドレス管理に移動してください" ,
  "交易完成" :"トランザクションが完了しました" ,
  "已撤消" :"取り消し" ,
  "次" :"回数" ,
  "份额占比" :"シェア割合" ,
  "暂时没有数据" :"まだデータがありません" ,
  "登录密码" :"ログインパスワード" ,
  "提现密码错误":"出金パスワードが間違っています" ,
  "请输入邮箱或手机":"メールアドレスまたは携帯電話番号を入力してください" ,
  "实名资料提交成功，请耐心等待管理员审核" :"実名情報は正常に送信されました。管理者が確認するまでしばらくお待ちください。",
  "当前交割":"現在の納期",
  "交割历史":"納品履歴",
  "您已添加钱包地址":"您已添加钱包地址",
  "添加提现地址" :"添加提现地址",
  "人民币折合" :"人民元換算",
};