<template>
    <div class="container page">
        <van-nav-bar :title="$t('mine.Accountdetails')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="wrapper">
            <div class="add-card" @click="toBindCard()" v-if="!is_bind">
                <van-icon name="plus" />
                <span>{{ $t('mine.addpaymentcard') }}</span>
            </div>
            <div class="bank" v-else>
                <div class="bank-cell">
                    <span>{{ $t('mine.bank_name') }}:</span>
                    <span>{{ this.bankInfo.bank_name }}</span>
                </div>
                <div class="bank-cell">
                    <span>{{ $t('mine.branch_name') }}:</span>
                    <span>{{ this.bankInfo.branch_name }}</span>
                </div>
                <div class="bank-cell">
                    <span>{{ $t('mine.branch_code') }}:</span>
                    <span>{{ this.bankInfo.branch_code }}</span>
                </div>
                <div class="bank-cell">
                    <span>{{ $t('mine.transaction_type') }}:</span>
                    <span>{{ this.bankInfo.transaction_type }}</span>
                </div>
                <div class="bank-cell">
                    <span>{{ $t('mine.account_number') }}:</span>
                    <span>{{ this.bankInfo.account_number }}</span>
                </div>
                <div class="bank-cell">
                    <span>{{ $t('mine.account_holder_name') }}:</span>
                    <span>{{ this.bankInfo.account_holder_name }}</span>
                </div>
                <div class="bank-cell">
                    <span>{{ $t('mine.account_holder_name_katakana') }}:</span>
                    <span>{{ this.bankInfo.account_holder_name_katakana }}</span>
                </div>
            </div>
            <div class="tips">{{ $t('mine.cardtips') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            is_bind: false,
            bankInfo: {},
            userInfo: {}
        }
    },
    methods: {
        back() {
            return window.history.back()
        },
        getUserBankInfo() {
            this.$http({
                method: 'get',
                url: 'user_get_bank'
            }).then((res) => {
                if (res.code === 200) {
                    if (res.data.is_bank) {
                        this.is_bind = true
                        this.bankInfo = res.data.info
                    } else {
                        this.is_bind = false
                    }
                } else if (res.code === 401) {
                    this.$toast(res.msg)
                }
            })
        },
        getUserInfo() {
            this.$http({
                method: 'get',
                url: 'user_info'
            }).then((res) => {
                if (res.code === 200) {
                    this.userInfo = res.data
                    this.name = res.data.name
                } else if (res.code === 401) {
                    this.$toast(res.msg)
                }
            })
        },
        toBindCard() {
            if (!this.userInfo.name) {
                this.$router.push('Setname')
                this.$toast(this.$t('mine.Setname'))
                return true
            } else if (!this.userInfo.paypassword) {
                this.$router.push('SetPayPassword')
                this.$toast(this.$t('mine.SetPayPassword'))
                return true
            } else {
                this.$router.push({ path: '/BindCard' })
            }
        }
    },
    created() {
        if (!localStorage.getItem('token')) {
            this.$router.push({ path: '/Login' })
        } else {
            this.getUserInfo()
            this.getUserBankInfo()
        }
    }
}
</script>

<style lang='less' scoped>
@import '../../assets/css/base.css';

.wrapper .add-card {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c24491;
    background-color: #fff;
    height: 13.333vw;
}

.wrapper .add-card span {
    margin-left: 1.333vw;
    font-size: 5.333vw;
}
.van-cell {
    font-size: 35px;
    line-height: 80px;
}

.container p {
    padding: 0 15px;
    margin-top: 15px;
    font-size: 30px;
    color: #dc2037;
}
.manage-card .wrapper {
    height: calc(100% - 10px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.wrapper .tips {
    margin: 30px 30px;
    font-size: 25px;
    color: #979799;
}
.wrapper .bank .info {
    margin-left: 20px;
    flex: 1;
    color: #000;
}
.wrapper .bank .info .row-content {
    margin: 30px 0;
    line-height: 20px;
    font-size: 30px;
}
.wrapper .bank{
    width: 90%;
    margin: auto;
}
.wrapper .bank .bank-cell{
    line-height: 40px;
    font-size: 4.5vw;
    padding: 4vw 4vw;
    background: #fff;
    margin: 4vw 0 0 0;
    span{
        margin-right: 5px;
    }
}
</style>
